import http from "../http-common";
import AuthService from "./AuthService";

export class TimetableService {
  protected url: string = "/roster";

  userroster(locationUuid: string, date: string) {
    if (AuthService.isLoggedIn()) {
      return http.get(`${this.url}/user`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { location: locationUuid, date: date } });
    } else {
      return http.get(`${this.url}/user`, { headers: { ipAddress: AuthService.getIp() }, params: { location: locationUuid, date: date } });
    }
  }

  roster(locationUuid: string, date: string) {
    if (AuthService.isLoggedIn()) {
      return http.get(`${this.url}`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { location: locationUuid, date: date } });
    } else {
      return http.get(`${this.url}`, { headers: { ipAddress: AuthService.getIp() }, params: { location: locationUuid, date: date } });
    }
  }

  get(uuid: string) {
    return http.get(`${this.url}/${uuid}`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() } });
  }

  register(uuid: string, date: string, status: any) {
    return http.post(`${this.url}/${uuid}`, status, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { date: date } });
  }

  book(uuid: string, date: string, status: any) {
    return http.post(`${this.url}/${uuid}/book`, status, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { date: date } });
  }

  load(uuid: string, event: string, date: string) {
    return http.get(`${this.url}/${uuid}`, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { event: event, date: date } });
  }

  save(uuid: string, event: string, date: string, roster: any) {
    return http.put(`${this.url}/${uuid}`, roster, { headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() }, params: { event: event, date: date } });
  }

  download(location: string, startDate: string, endDate: string) {
    return http.request({
      method: 'get',
      url: `${this.url}/download`,
      headers: { Authorization: AuthService.token() },
      params: { location, startDate, endDate },
      responseType: 'blob'
    });
  }

  userdownload(location: string, personal: boolean, startDate: string, endDate: string) {
    return http.request({
      method: 'get',
      url: `${this.url}/userdownload`,
      headers: { Authorization: AuthService.token() },
      params: { location, startDate, endDate, personal },
      responseType: 'blob'
    });
  }

  send(location: string, startDate: string, endDate: string) {
    return http.put(`${this.url}/send`, {}, {
      headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() },
      params: { location, startDate, endDate }
    });
  }

  approve(location: string, startDate: string, endDate: string, approve: any) {
    return http.put(`${this.url}/approve`, approve, {
      headers: { ipAddress: AuthService.getIp(), Authorization: AuthService.token() },
      params: { location, startDate, endDate }
    });
  }

}

export default new TimetableService();
