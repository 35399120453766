









































































































































































































































import AuthService from "@/services/AuthService";
import RosterService from "@/services/RosterService";
import LocationService from "@/services/LocationService";
import moment from "moment";
import "moment/locale/de";
import { Component, Vue } from "vue-property-decorator";
import router from "../router/index";

@Component
export default class Home extends Vue {
  private timetable: any[] = [];
  private locations: any[] = [];
  private services: any[] = [];
  private downloadLocationUuid: string = "";
  private startDate: any = null;
  private endDate: any = null;
  private uuid: string = "";
  private personal: boolean = false;
  private approved: boolean = false;
  private month: string = router.currentRoute.query.date as string;
  private locationUuid: string = router.currentRoute.query.location as string;

  colclass(roster: any) {
    if (roster.item.status === "CANCELLED") {
      return "event_cancelled";
    }

    return "";
  }

  change(event: any) {
    this.reload(this.uuid, event);
  }

  selectLocation(uuid: any) {
    this.reload(uuid, this.month);
  }

  save(roster: any) {
    const variants: any = { YES: "success", NO: "danger" };

    RosterService.register(roster.event.uuid, this.date2(roster.date), <any>{
      uuid: roster.userStatus.uuid,
      availability: roster.userStatus.availability,
      services: roster.userStatus.services,
    }).then((response) =>
      this.$bvToast.toast(
        `${roster.item.event.name} ${this.date(roster.date)} ${
          roster.item.startTime
        }`,
        {
          title: "Änderung gespeichert",
          variant: variants[roster.userStatus.availability],
          autoHideDelay: 3000,
          appendToast: true,
        }
      )
    );
  }

  date(date: any) {
    return moment(date).format("dddd, DD.MM.YYYY");
  }

  date2(date: any) {
    return moment(date).format("YYYY-MM-DD");
  }

  reload(locationUuid: string, date: string) {
    this.timetable = [];
    this.uuid = locationUuid || "";
    this.month = date || this.date2(new Date().setDate(1));

    LocationService.all("name").then((loc) => {
      this.locations = loc.data.content;
    });
    RosterService.userroster(locationUuid, date).then((response) => {
      this.timetable = response.data;
      this.approved =
        response.data.filter((r: any) => r.approval === "OPEN").length === 0;
      router.replace({
        name: "userroster",
        query: { location: this.uuid, date: this.month },
      });
    });
  }

  initDownload() {
    const d = new Date(this.month);
    d.setMonth(d.getMonth() + 1);
    d.setDate(d.getDate() - 1);

    this.startDate = this.month;
    this.endDate = this.date2(d);
    this.downloadLocationUuid = this.uuid;
    this.$bvModal.show("download");
  }

  download() {
    RosterService.userdownload(
      this.downloadLocationUuid,
      this.personal,
      this.startDate,
      this.endDate
    ).then((response: any) => {
      var downloadURL = URL.createObjectURL(
        new Blob([response.data], { type: "application/pdf" })
      );
      const anchor = document.createElement("a");
      anchor.download = `Dienstplan-${
        (
          this.locations.filter(
            (l) => l.uuid === this.downloadLocationUuid
          )[0] || { name: "Alle_Orte" }
        ).name
      }-${moment(this.month).format("YYYY-MM")}.pdf`;
      anchor.href = downloadURL;
      anchor.target = "_blank";
      anchor.click();
    });
  }

  mounted() {
    moment.locale("de");
    moment.weekdays(true);

    AuthService.user().then((response) => {
      this.services = response.data.services;
      this.reload(
        this.locationUuid || response.data.location,
        this.month || this.date2(new Date().setDate(1))
      );
    });
  }
}
